import React from "react";
import {Link} from 'gatsby';

const Nav = (props) => {
  return (
    <nav className="nav flex flex-col text-center text-white font-semibold sm:flex-row sm:text-left sm:justify-between py-4 px-6 bg-opacity-0 sm:items-baseline w-full">
      <Link to="/" className="text-lg no-underline hover:text-sky-offwhite ml-2">
        cd ~/
      </Link>
      <div className="mb-2 sm:mb-0"></div>
      <div>
        <Link to="/hello" className="text-lg no-underline hover:text-sky-offwhite ml-2">
          Hello
        </Link>
        &nbsp;&nbsp;|&nbsp;
        {/* <a href="https://therecipe.io" rel="noreferrer" target="_blank" className="text-lg no-underline hover:text-sky-offwhite ml-2">
          Blog
        </a>
        &nbsp;&nbsp;| */}
        <a
          href="mailto:hello@bilalelreda.com"
          className="text-lg no-underline hover:text-sky-offwhite ml-2"
        >
          Contact
        </a>
      </div>
    </nav>
  );
};

export default Nav;
