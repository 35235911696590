import React from "react";
import Nav from './Nav';

const Header = (props) => {
  return (
    <Nav />
  );
};

export default Header;
